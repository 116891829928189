export const togglePasswordVisibility = {
    data() {
        return {
            passwordFields: {
                password: 'password',
            },
        }
    },
    computed: {
        passwordIconClasses() {
            return Object.fromEntries(Object.entries(this.passwordFields)
                .map(([k, v]) => [k, (v === 'password' ? 'mdi-eye-outline' : 'mdi-eye-off-outline')]));
        },
    },
    methods: {
        togglePasswordVisibility(field) {
            this.passwordFields[field] = this.passwordFields[field] === 'password' ? 'text' : 'password'
        },
    },
}
