<script>
import { ErrorMessage, Field, Form } from 'vee-validate';
import { validationMixin } from '@/mixins/form/validation';
import { togglePasswordVisibility } from '@/mixins/form/toggle-password-visibility';

export default {
  name: 'AccountSettingsPopup',
  mixins: [validationMixin, togglePasswordVisibility],
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  props: {
    account: {
      type: Object,
    },
  },
  data() {
    return {
      form: {
        old_password: null,
        new_password: null,
        new_password_confirmation: null,
      },
      passwordFields: {
        old_password: 'password',
        new_password: 'password',
        new_password_confirmation: 'password',
      },
      loading: {
        submit: false,
      },
      modalShow: true,
    };
  },
  methods: {
    handleSubmit(values, actions) {
      this.loading.submit = true;

      this.axios
        .patch(`auth/accounts/${this.account.id}/password`, this.form)
        .then(() => {
          this.$notify({
            type: 'success',
            text: this.$t('Account.SuccessPasswordCahnged'),
          });
          this.modalShow = false;
        })
        .catch(({ response }) => {
          this.handleResponseErrors(response, { values, actions });
        })
        .finally(() => {
          this.loading.submit = false;
        });
    },
  },
};
</script>

<template>
  <b-modal
    v-model="modalShow"
    id="add-demo-deposit-popup"
    hide-footer
    centered
    header-class="border-0"
    lazy
    :title="$t('Accounts.ChangeAccountPassword')"
    @hidden="$emit('closeModal')"
  >
    <b-overlay :show="loading.submit" spinner-variant="primary" rounded="sm">
      <div>
        <Form ref="form" @submit="handleSubmit">
          <Field
            name="old_password"
            rules="required"
            :label="$t('Accounts.OldAccountPassword')"
            v-slot="{ errors, field }"
          >
            <b-form-group class="mb-3 text-muted" :label="$t('Accounts.OldAccountPassword')" label-for="old_password">
              <b-input-group>
                <b-form-input
                  id="old_password"
                  v-model="form.old_password"
                  :type="passwordFields.old_password"
                  class="form-control-merge"
                  :class="{ 'is-invalid': errors.length }"
                  v-bind="field"
                />
                <b-input-group-append is-text :class="{ 'is-invalid': errors.length }">
                  <i
                    class="mdi cursor-pointer text-muted font-size-14"
                    :class="[passwordIconClasses.old_password]"
                    @click="togglePasswordVisibility('old_password')"
                  ></i>
                </b-input-group-append>
              </b-input-group>

              <ErrorMessage name="old_password" class="invalid-feedback d-block" />
            </b-form-group>
          </Field>

          <Field
            name="new_password"
            rules="required|min:8"
            :label="$t('Accounts.NewAccountPassword')"
            v-slot="{ errors, field }"
          >
            <b-form-group class="mb-3 text-muted" :label="$t('Accounts.NewAccountPassword')" label-for="new_password">
              <b-input-group>
                <b-form-input
                  id="new_password"
                  v-model="form.new_password"
                  :type="passwordFields.new_password"
                  class="form-control-merge"
                  :class="{ 'is-invalid': errors.length }"
                  v-bind="field"
                />
                <b-input-group-append is-text :class="{ 'is-invalid': errors.length }">
                  <i
                    class="mdi cursor-pointer text-muted font-size-14"
                    :class="[passwordIconClasses.new_password]"
                    @click="togglePasswordVisibility('new_password')"
                  ></i>
                </b-input-group-append>
              </b-input-group>

              <ErrorMessage name="new_password" class="invalid-feedback d-block" />
            </b-form-group>
          </Field>

          <Field
            name="new_password_confirmation"
            rules="required|confirmed:@new_password"
            :label="$t('Accounts.RepeatNewAccountPassword')"
            v-slot="{ errors, field }"
          >
            <b-form-group
              class="mb-3 text-muted"
              :label="$t('Accounts.RepeatNewAccountPassword')"
              label-for="new_password_confirmation"
            >
              <b-input-group>
                <b-form-input
                  id="new_password_confirmation"
                  v-model="form.new_password_confirmation"
                  :type="passwordFields.new_password_confirmation"
                  class="form-control-merge"
                  :class="{ 'is-invalid': errors.length }"
                  v-bind="field"
                />
                <b-input-group-append is-text :class="{ 'is-invalid': errors.length }">
                  <i
                    class="mdi cursor-pointer text-muted font-size-14"
                    :class="[passwordIconClasses.new_password_confirmation]"
                    @click="togglePasswordVisibility('new_password_confirmation')"
                  ></i>
                </b-input-group-append>
              </b-input-group>

              <ErrorMessage name="new_password_confirmation" class="invalid-feedback d-block" />
            </b-form-group>
          </Field>

          <button type="submit" class="btn btn-dark btn-sm w-lg" ref="submitBtn">
            {{ $t('Accounts.UpdatePassword') }}
          </button>
        </Form>
      </div>
    </b-overlay>
  </b-modal>
</template>
