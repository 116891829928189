<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
import DatePeriodSelect from '@/components/common/form/DatePeriodSelect';
import AccountTransfers from '@/components/pages/accounts/account-transfers';
import OrderHistory from '@/components/pages/accounts/order-history';
import BtcIcon from '@/components/common/currency-icons/btc';
import EthIcon from '@/components/common/currency-icons/eth';
import CurrencyIcon from '@/components/common/currency-icons/currency';
import AddDemoDepositPopup from '@/components/modals/AddDemoDepositPopup';
import AccountSettingsPopup from '@/components/modals/AccountSettingsPopup';
import { CURRENCY_SIGN } from '@/constants/currency';
import { mapState } from 'vuex';
import Multiselect from '@vueform/multiselect';

export default {
  page: {
    title: 'BTC',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    DatePeriodSelect,
    AccountTransfers,
    OrderHistory,
    BtcIcon,
    EthIcon,
    CurrencyIcon,
    AddDemoDepositPopup,
    AccountSettingsPopup,
    Multiselect,
  },
  data() {
    return {
      title: 'USD',
      chartData: [],
      chartLables: [],
      statistics: [],
      period: [
        this.$moment(new Date()).lang('en').startOf('day').subtract(7, 'd').format(),
        this.$moment(new Date()).lang('en').startOf('day').format(),
      ],
      loading: {
        chartData: false,
        statistics: false,
        leverage: false,
      },
      leverages: {},
      accountLeverage: null,
      CURRENCY_SIGN,
      showDepositModal: false,
      showSettingModal: false,
      enabledLeverageEnabled: false,
    };
  },
  computed: {
    ...mapState('accounts', { loadingAccounts: (state) => state.loading, accounts: (state) => state.accounts }),
    ...mapState('auth', ['visibleBalance']),
    accountID() {
      return this.$route.params.id;
    },
    account() {
      return this.accounts.find((acc) => +acc.id === +this.accountID);
    },
    chartPeriod() {
      return this.$moment(this.period[1]).diff(this.$moment(this.period[0]), 'days');
    },
    overviewChart() {
      return {
        series: [
          {
            type: 'area',
            name: this.account?.currency.key,
            data: this.chartData,
          },
        ],
        chartOptions: {
          chart: {
            type: 'line',
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
            width: 2,
            dashArray: [0, 0, 3],
          },
          fill: {
            type: 'solid',
            opacity: [0.15, 0.05, 1],
          },
          xaxis: {
            categories: this.chartLables,
          },
          tooltip: {
            fixed: {
              enabled: false,
            },
            x: {
              show: false,
            },
            marker: {
              show: false,
            },
          },
          colors: ['#f1b44c'],
        },
      };
    },
  },
  watch: {
    accountID() {
      this.fetchChartData();
      this.fetchStatistics();
    },
  },
  methods: {
    onLeverageSelect(value) {
      this.loading.leverage = true;
      this.axios
        .patch(`accounts/${this.accountID}/leverage`, { leverage: value })
        .then(() => {
          this.$notify({
            type: 'success',
            text: this.$t('Accounts.AccountLeverageUpdated'),
          });
        })
        .finally(() => {
          this.loading.leverage = false;
        });
    },
    async toggleAccountLeverage() {
      await this.$nextTick();
      this.loading.leverage = true;
      this.axios
        .patch(`accounts/${this.accountID}/leverage`, { enable_account_leverage: this.enabledLeverageEnabled })
        .then(({ data }) => {
          this.$notify({
            type: 'success',
            text: data.enable_account_leverage
              ? this.$t('Accounts.AccountLeverageEnabled')
              : this.$t('Accounts.AccountLeverageDisabled'),
          });
        })
        .finally(() => {
          this.loading.leverage = false;
        });
    },
    openDepositModal() {
      this.showDepositModal = true;
    },
    fetchChartData() {
      this.loading.chartData = true;

      const params = {
        start: this.period[0],
        end: this.period[1],
        with_label: 1,
      };

      this.axios
        .get(`accounts/${this.accountID}/statistics/balance`, { params })
        .then(({ data }) => {
          this.chartData = [];
          this.chartLables = [];
          data.forEach((d) => {
            this.chartData.push(d.value);

            if (this.chartPeriod <= 30) {
              this.chartLables.push(this.$moment(d.label).locale(this.$i18n.locale).format('DD MMM'));
              return;
            }

            if (this.chartPeriod > 30) {
              this.chartLables.push(this.$moment(d.label).locale(this.$i18n.locale).format('MMM'));
              return;
            }
          });
        })
        .finally(() => {
          this.loading.chartData = false;
        });
    },
    fetchStatistics() {
      this.loading.statistics = true;
      this.axios
        .get(`accounts/${this.accountID}/statistics`)
        .then(({ data }) => {
          this.statistics = data;
        })
        .finally(() => {
          this.loading.statistics = false;
        });
    },
    fetchLeverage() {
      this.loading.leverage = true;
      return this.axios
        .get(`/accounts/leverage`)
        .then(({ data }) => {
          this.leverages = Object.fromEntries(Object.entries(data).map(([key, value]) => [value, key]));
        })
        .finally(() => {
          this.loading.leverage = false;
        });
    },
    formattedNumber(number) {
      return Number.isInteger(number) ? number.toString() : number.toFixed(2);
    },
  },
  mounted() {
    this.fetchLeverage();
    this.fetchStatistics();
    this.fetchChartData();
  },
  created() {
    this.enabledLeverageEnabled = this.account.enable_account_leverage;
  },
  metaInfo() {
    return {
      title: this.$t('Accounts.Account'),
    };
  },
};
</script>

<template>
  <Layout>
    <b-overlay :show="loadingAccounts.accounts" spinner-variant="primary" rounded="sm">
      <PageHeader :title="account?.currency.key">
        <template v-slot:center>
          <ul class="nav">
            <li v-for="account in accounts" :key="account.id" class="nav-item" role="presentation">
              <router-link
                :to="{ name: 'accounts-show', params: { id: account.id } }"
                :class="[
                  'nav-link',
                  account?.is_demo ? 'text-primary' : 'text-success',
                  +account.id === +accountID ? 'active' : '',
                ]"
              >
                {{ account?.name }}-{{ account?.currency.key }}
              </router-link>
            </li>
          </ul>
        </template>

        <template v-slot:right>
          <router-link :to="{ name: 'accounts' }" class="btn btn-light btn-sm px-3">
            {{ $t('Accounts.BackAllAccounts') }}
          </router-link>
        </template>
      </PageHeader>

      <div class="row">
        <div class="col-xl-4">
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="d-flex">
                  <div class="flex-shrink-0 me-4">
                    <btc-icon v-if="account?.currency.key === 'BTC'" size="26" />
                    <eth-icon v-else-if="account?.currency.key === 'ETH'" size="26" />
                    <currency-icon v-else :symbol="account.currency?.symbol || account.currency?.key" :size="26" />
                  </div>

                  <div class="flex-grow-1">
                    <div class="text-muted">
                      <h5>{{ account?.name }}</h5>
                      <p class="mb-1">{{ $moment(account?.created_at).format('DD.MM.YYYY') }}</p>
                      <p class="mb-0">{{ $t('Common.IdNo') }}: #{{ account?.number }}</p>
                    </div>
                  </div>
                </div>

                <div>
                  <i class="fa fa-cog cursor-pointer" style="font-size: 18px" @click="showSettingModal = true"></i>
                </div>
              </div>
            </div>
            <div class="card-body border-top">
              <div class="row">
                <div class="col-sm-6">
                  <div>
                    <p class="text-muted mb-2">{{ $t('Accounts.AvailableBalance') }}</p>
                    <h1 class="text-nowrap">
                      <template v-if="visibleBalance">{{ account?.amount }}</template
                      ><template v-else>***</template> {{ account?.currency.key }}
                    </h1>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="text-sm-end mt-4 mt-sm-0">
                    <div class="d-flex justify-content-end">
                      <b-spinner v-if="loading.statistics" small class="me-2"></b-spinner>
                      <p class="text-muted mb-2">{{ $t('Accounts.CurrentProfit') }}</p>
                    </div>

                    <h5 v-if="statistics">
                      <span :class="['badge', 'ms-1', 'align-bottom', statistics.profit > 0 ? 'bg-success' : 'bg-danger']">
                        {{ formattedNumber(statistics.profit) }} %
                      </span>
                    </h5>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-body border-top">
              <div class="d-flex">
                <p class="text-muted mb-4 me-2">{{ $t('Accounts.InThisMonth') }}</p>
                <b-spinner v-if="loading.statistics" small></b-spinner>
              </div>
              <div class="text-center">
                <div class="row">
                  <div class="col-sm-4">
                    <div>
                      <div class="font-size-24 text-dark mb-2">
                        <i class="bx bx-wallet"></i>
                      </div>

                      <p class="text-muted mb-2 mh40">{{ $t('Transaction.Deposit') }}</p>

                      <h5>{{ statistics.deposits }} {{ account?.currency.symbol }}</h5>

                      <div class="mt-3">
                        <button v-if="account?.is_demo" class="btn btn-dark btn-sm w-md" @click="openDepositModal">
                          {{ $t('Accounts.Deposit') }}
                        </button>
                        <router-link v-else :to="{ name: 'deposit' }" class="btn btn-dark btn-sm w-md">{{
                          $t('Accounts.Deposit')
                        }}</router-link>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="mt-4 mt-sm-0">
                      <div class="font-size-24 text-dark mb-2">
                        <i class="bx bx-import"></i>
                      </div>

                      <p class="text-muted mb-2 mh40">{{ $t('Accounts.TransferBetweenAccounts') }}</p>
                      <h5>{{ statistics.transfers }} {{ account?.currency.symbol }}</h5>

                      <div class="mt-3">
                        <router-link :to="{ name: 'swap' }" class="btn btn-dark btn-sm w-md">{{
                          $t('Accounts.Transfer')
                        }}</router-link>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="mt-4 mt-sm-0">
                      <div class="font-size-24 text-dark mb-2">
                        <i class="bx bx-send"></i>
                      </div>

                      <p class="text-muted mb-2 mh40">{{ $t('Transaction.Withdraw') }}</p>
                      <h5>{{ statistics.withdrawals }} {{ account?.currency.symbol }}</h5>

                      <div class="mt-3">
                        <button v-if="account?.is_demo" disabled class="btn btn-dark btn-sm w-md">
                          {{ $t('Accounts.Withdraw') }}
                        </button>

                        <router-link
                          v-else
                          :to="{ name: 'withdraw' }"
                          :event="true ? 'click' : ''"
                          class="btn btn-dark btn-sm w-md"
                        >
                          {{ $t('Accounts.Withdraw') }}
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-8 d-flex flex-column">
          <div class="row mb-4">
            <div class="col-sm-6 d-flex flex-column">
              <div class="card mb-0 mini-stats-wid flex-grow-1">
                <div class="card-body d-flex flex-column">
                  <div class="d-flex flex-grow-1">
                    <div class="me-3 align-self-center">
                      <i class="bx bx-send h2 text-dark mb-0"></i>
                    </div>
                    <div class="flex-grow-1">
                      <div class="d-flex">
                        <p class="text-muted mb-2 me-2">{{ $t('Accounts.ForWithdraw') }}</p>
                        <b-spinner v-if="loading.statistics" small></b-spinner>
                      </div>
                      <h5 class="mb-0">{{ statistics.for_withdraw }} {{ account?.currency.key }}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- div class="col-sm-4">
              <div class="card mini-stats-wid">
                <div class="card-body">
                  <div class="d-flex">
                    <div class="me-3 align-self-center">
                      <i class="bx bx-check-circle h2 text-dark mb-0"></i>
                    </div>
                    <div class="flex-grow-1">
                      <div class="d-flex">
                        <p class="text-muted mb-2 me-2">{{ $t('Accounts.Bonuses') }}</p>
                        <b-spinner v-if="loading.statistics" small></b-spinner>
                      </div>
                      <h5 class="mb-0">0 {{ account?.currency.key }}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div -->
            <div class="col-sm-6 d-flex flex-column">
              <div class="card mb-0 mini-stats-wid flex-grow-1">
                <div class="card-body d-flex flex-column">
                  <div class="d-flex flex-grow-1">
                    <div class="me-3 align-self-center">
                      <i class="bx bx-archive-in h2 text-dark mb-0"></i>
                    </div>

                    <div class="w-100">
                      <div class="d-flex">
                        <p class="text-muted mb-2 me-2">{{ $t('Accounts.Leverage') }}</p>
                      </div>

                      <div class="flex-grow-1 d-flex align-items-center justify-content-between">
                        <Multiselect
                          class="leverage-multiselect me-4"
                          :value="account.leverage"
                          :options="leverages"
                          :canClear="false"
                          :rtl="$i18n.locale === 'ar'"
                          :disabled="!enabledLeverageEnabled || loading.leverage"
                          :loading="loading.leverage"
                          @select="onLeverageSelect"
                        >
                        </Multiselect>

                        <label class="leverage-checkbox align-items-center">
                          <span v-if="enabledLeverageEnabled" class="leverage-checkbox__label">{{
                            $t('Accounts.AccountLeverage')
                          }}</span>
                          <span v-else class="leverage-checkbox__label">{{ $t('Accounts.DefaultLeverage') }}</span>

                          <b-form-checkbox
                            v-model="enabledLeverageEnabled"
                            class="allow-checkbox leverage-checkbox__toggle"
                            name="check-button"
                            switch
                            :disabled="loading.leverage"
                            @input="toggleAccountLeverage"
                          >
                          </b-form-checkbox>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card flex-grow-1">
            <div class="card-body d-flex flex-column">
              <div class="clearfix">
                <div class="float-end">
                  <date-period-select v-model="period" @update:modelValue="fetchChartData()" />
                </div>
                <div class="d-flex">
                  <h4 class="card-title mb-3 me-2">{{ $t('Accounts.Overview') }}</h4>
                  <b-spinner v-if="loading.chartData" small></b-spinner>
                </div>
              </div>

              <div class="flex-grow-1">
                <apexchart
                  class="apex-charts"
                  height="280"
                  type="line"
                  dir="ltr"
                  :series="overviewChart.series"
                  :options="overviewChart.chartOptions"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <b-tabs>
                <b-tab :title="$t('Dashboard.OrderHistory')" active lazy>
                  <order-history :title="null" :accountId="+$route.params.id" />
                </b-tab>

                <b-tab :title="$t('Transaction.Transfers')" lazy>
                  <account-transfers :title="null" :accountId="+$route.params.id" />
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>

    <AddDemoDepositPopup
      v-if="showDepositModal"
      :account="{ ...account, accountLeverage: leverages[account?.leverage] }"
      @closeModal="showDepositModal = false"
    />

    <AccountSettingsPopup v-if="showSettingModal" :account="account" @closeModal="showSettingModal = false" />
  </Layout>
</template>

<style scoped lang="scss">
.mh40 {
  min-height: 40px;
}

.nav {
  border-bottom: 1px solid rgba(129, 130, 141, 0.5);

  .active {
    font-weight: bold;
    border-bottom: 2px solid #05051d;
  }
}

::v-deep(.order-history-heading),
::v-deep(.account-transfers-heading) {
  margin-top: -50px;
  position: relative;
  z-index: 10;
  width: fit-content;
  margin-left: auto;

  @media (max-width: 575px) {
    margin-top: 0;
  }
}

.leverage-multiselect {
  max-width: 130px;
  border: none;
}

.leverage-checkbox {
  display: flex;
  font-size: 18px;

  &__label {
    text-transform: uppercase;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    font-weight: 400;
    color: #81828d;
    text-align: end;
    margin-right: 0.5rem;
  }
}
</style>

<style lang="scss">
.app-rtl .order-history-heading,
.app-rtl .account-transfers-heading {
  margin-right: auto;
  margin-left: 0;
}

.app-rtl {
  .leverage-checkbox__label {
    margin-right: 0;
    margin-left: 2.5rem;
  }
}
</style>
